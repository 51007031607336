module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    "use strict";
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<% var i18n = require(\'@cdo/locale\'); %>\n<% function getPrefixedName () {\n  return locals.tipPrefix ? locals.tipPrefix + locals.functionName : locals.functionName;\n} %>\n<div class="function-name">\n  <% if (locals.signatureOverride) { -%>\n    <%= locals.signatureOverride %>\n  <% } else if (locals.isProperty) { -%>\n    <%= getPrefixedName() %>\n  <% } else {\n    /**\n     * TODO(bjordan): would be nice to split the following line up, can\'t figure\n     * out how to do so without inserting extraneous spaces between locals.parameters.\n     */\n    -%>\n    <%= getPrefixedName() %>(<% for (var i = 0; i < locals.parameters.length; i++) { -%><%- locals.parameters[i].name -%><% if (i < locals.parameters.length - 1) { -%>, <% } -%><% } -%>)\n  <% } %>\n</div>\n<% if (locals.functionShortDescription) { %>\n  <% locals.functionShortDescription.split(\'\\n\').map(function(descriptionLine) { %>\n    <div>\n      <%= descriptionLine %>\n    </div>\n  <% }) %>\n<% } %>\n<% var linkText = "" %>\n<% if (locals.showCodeLink) { %>\n  <div class="tooltip-code-link">\n    <a href="#"><%= i18n.showGeneratedCode() %></a>\n  </div>\n<% } %>\n<% if (locals.showExamplesLink) { %>\n  <div class="tooltip-example-link">\n    <a href="#"><%= i18n.examples() %></a>\n  </div>\n<% } %>\n', __filename = "src/blockTooltips/DropletFunctionTooltip.html.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        var i18n = require("@cdo/locale");
        __append("\n");
        __line = 2;
        function getPrefixedName() {
            return locals.tipPrefix ? locals.tipPrefix + locals.functionName : locals.functionName;
        }
        __line = 4;
        __append('\n<div class="function-name">\n  ');
        __line = 6;
        if (locals.signatureOverride) {
            __append("    ");
            __line = 7;
            __append(escapeFn(locals.signatureOverride));
            __append("\n  ");
            __line = 8;
        } else if (locals.isProperty) {
            __append("    ");
            __line = 9;
            __append(escapeFn(getPrefixedName()));
            __append("\n  ");
            __line = 10;
        } else {
            __line = 15;
            __append("    ");
            __line = 16;
            __append(escapeFn(getPrefixedName()));
            __append("(");
            for (var i = 0; i < locals.parameters.length; i++) {
                __append(locals.parameters[i].name);
                if (i < locals.parameters.length - 1) {
                    __append(", ");
                }
            }
            __append(")\n  ");
            __line = 17;
        }
        __append("\n</div>\n");
        __line = 19;
        if (locals.functionShortDescription) {
            __append("\n  ");
            __line = 20;
            locals.functionShortDescription.split("\n").map(function(descriptionLine) {
                __append("\n    <div>\n      ");
                __line = 22;
                __append(escapeFn(descriptionLine));
                __append("\n    </div>\n  ");
                __line = 24;
            });
            __append("\n");
            __line = 25;
        }
        __append("\n");
        __line = 26;
        var linkText = "";
        __append("\n");
        __line = 27;
        if (locals.showCodeLink) {
            __append('\n  <div class="tooltip-code-link">\n    <a href="#">');
            __line = 29;
            __append(escapeFn(i18n.showGeneratedCode()));
            __append("</a>\n  </div>\n");
            __line = 31;
        }
        __append("\n");
        __line = 32;
        if (locals.showExamplesLink) {
            __append('\n  <div class="tooltip-example-link">\n    <a href="#">');
            __line = 34;
            __append(escapeFn(i18n.examples()));
            __append("</a>\n  </div>\n");
            __line = 36;
        }
        __append("\n");
        __line = 37;
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}