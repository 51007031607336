/* eslint-disable */

// apps/src/styleConstants.js
// GENERATED FILE: DO NOT MODIFY DIRECTLY
// This generated file exports all variables defined in shared/css/style-constants.scss
// for use in JavaScript. The generator script is convert-scss-variables.js
module.exports = {
  "workspace-headers-height": 30,
  "resize-bar-width": 13,
  "delete-opacity": "0.5",
  "content-width": 970,
};
