// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lqUj836Vw6y9fMKC7bEQ{display:flex;flex-direction:row;align-items:flex-end;flex-wrap:wrap}.lqUj836Vw6y9fMKC7bEQ .QEVW8SGg6fyk57O2Rg89{margin-left:10px;margin-bottom:10px}.lqUj836Vw6y9fMKC7bEQ .DZvvRe31RrYG94q0JhwX{margin-left:10px;margin-bottom:10px}.lqUj836Vw6y9fMKC7bEQ .lsdAx9S0Rnr2QXzSMVVK{display:flex;flex-direction:column;margin-bottom:5px}", "",{"version":3,"sources":["webpack://./src/templates/courseOverview/course-overview.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,oBAAA,CACA,cAAA,CAEA,4CACE,gBAAA,CACA,kBAAA,CAGF,4CACE,gBAAA,CACA,kBAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,iBAAA","sourcesContent":[".actionRow {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-end;\n  flex-wrap: wrap;\n\n  .teacherResources {\n    margin-left: 10px;\n    margin-bottom: 10px;\n  }\n\n  .studentResources {\n    margin-left: 10px;\n    margin-bottom: 10px;\n  }\n\n  .assignButton {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionRow": "lqUj836Vw6y9fMKC7bEQ",
	"teacherResources": "QEVW8SGg6fyk57O2Rg89",
	"studentResources": "DZvvRe31RrYG94q0JhwX",
	"assignButton": "lsdAx9S0Rnr2QXzSMVVK"
};
export default ___CSS_LOADER_EXPORT___;
