module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    "use strict";
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<%\n/**\n * This block destructures the local variables for use within this ejs template.\n */\nvar shareFailure = locals.shareFailure;\n%>\n<p id="share-fail-explanation"><%= shareFailure.message %></p>\n\n<% if (shareFailure.contents) { %>\n  <div class="share-fail-excerpt">\n    <pre class="generatedCode"><%= shareFailure.contents %></pre>\n  </div>\n<% } %>\n', __filename = "src/templates/shareFailure.html.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        var shareFailure = locals.shareFailure;
        __line = 6;
        __append('\n<p id="share-fail-explanation">');
        __line = 7;
        __append(escapeFn(shareFailure.message));
        __append("</p>\n\n");
        __line = 9;
        if (shareFailure.contents) {
            __append('\n  <div class="share-fail-excerpt">\n    <pre class="generatedCode">');
            __line = 11;
            __append(escapeFn(shareFailure.contents));
            __append("</pre>\n  </div>\n");
            __line = 13;
        }
        __append("\n");
        __line = 14;
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}