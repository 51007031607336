// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yvC73oiAWGb5wpUyufaZ{width:80%;box-sizing:border-box;resize:none;margin:0;flex:1}.jftsyPiUQREVnKCwm6Qt{padding:10px;display:flex;width:100%;height:60px;box-sizing:border-box;gap:5px}.P8KX1lvMODKVfyapLmt1{display:flex;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/aiComponentLibrary/userMessageEditor/user-message-editor.module.scss"],"names":[],"mappings":"AAEA,sBACI,SAAA,CACA,qBAAA,CACA,WAAA,CACA,QAAA,CACA,MAAA,CAGJ,sBACI,YAAA,CACA,YAAA,CACA,UAAA,CACA,WAdoB,CAepB,qBAAA,CACA,OAAA,CAGJ,sBACI,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":["$message-editor-height: 60px;\n\n.textArea {\n    width: 80%;\n    box-sizing: border-box;\n    resize: none;\n    margin: 0;\n    flex: 1;\n}\n\n.editorContainer {\n    padding: 10px;\n    display: flex;\n    width: 100%;\n    height: $message-editor-height;\n    box-sizing: border-box;\n    gap: 5px;\n}\n\n.centerSingleItemContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textArea": "yvC73oiAWGb5wpUyufaZ",
	"editorContainer": "jftsyPiUQREVnKCwm6Qt",
	"centerSingleItemContainer": "P8KX1lvMODKVfyapLmt1"
};
export default ___CSS_LOADER_EXPORT___;
