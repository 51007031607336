/* eslint-disable */

// apps/src/util/color.js
// GENERATED FILE: DO NOT MODIFY DIRECTLY
// This generated file exports all variables defined in shared/css/color.scss
// for use in JavaScript. The generator script is convert-scss-variables.js
module.exports = {
  "black": "#000",
  "background_black": "#121416",
  "dark_gray": "#2D3139",
  "darkest_gray": "#292F36",
  "dark_slate_gray": "#282c34",
  "darkest_slate_gray": "#25292d",
  "dark_charcoal": "#4d575f",
  "charcoal": "#5b6770",
  "light_gray": "#949ca2",
  "lighter_gray": "#c6cacd",
  "lightest_gray": "#e7e8ea",
  "background_gray": "#f2f2f2",
  "dimgray": "#696969",
  "white": "#fff",
  "default_blue": "#3670b3",
  "dark_teal": "#0094a3",
  "teal": "#0093a4",
  "applab_button_teal": "#1abc9c",
  "light_teal": "#59cad3",
  "lightish_teal": "#80d6de",
  "lighter_teal": "#a6e3e8",
  "lightest_teal": "#d9f3f5",
  "ai_rubric_cyan": "#3cfff8",
  "purple": "#8c52ba",
  "light_purple": "#a69bc1",
  "lighter_purple": "#cfc9de",
  "lightest_purple": "#ebe8f1",
  "cyan": "#0094ca",
  "light_cyan": "#59b9dc",
  "lighter_cyan": "#a6daed",
  "lightest_cyan": "#d9eff7",
  "almost_white_cyan": "#f5fcff",
  "orange": "#ffa400",
  "light_orange": "#ffc459",
  "lighter_orange": "#ffe0a6",
  "lightest_orange": "#fff2d9",
  "dark_orange": "#ff8600",
  "green": "#b9bf15",
  "light_green": "#d1d567",
  "lighter_green": "#e7e9ad",
  "lightest_green": "#f5f5dc",
  "highlight_green": "#8afc9b",
  "yellow": "#ffb81d",
  "light_yellow": "#ffdb74",
  "lighter_yellow": "#ffebb5",
  "lightest_yellow": "#fff7df",
  "goldenrod": "#daa520",
  "header_text": "#fff",
  "bkgnd_color": "#0093a4",
  "inset_color": "#c6cacd",
  "dark_color": "#8c52ba",
  "hdr_color": "#8c52ba",
  "red": "#c00",
  "lightest_red": "#fcc",
  "dark_red": "#d62911",
  "realgreen": "#008000",
  "realyellow": "#ff0",
  "mustardyellow": "#efcd1c",
  "twitter_blue": "#00aced",
  "facebook_blue": "#3b5998",
  "linkedin_blue": "#0077B5",
  "dark_blue": "#00647f",
  "blockly_flyout_gray": "#ddd",
  "default_text": "#333",
  "border_gray": "#bbb",
  "border_light_gray": "#d8d8d8",
  "table_header": "#ececec",
  "table_light_row": "#fcfcfc",
  "table_dark_row": "#f4f4f4",
  "level_submitted": "#8c52ba",
  "level_perfect": "rgb(14, 190, 14)",
  "level_passed": "rgb(159, 212, 159)",
  "level_attempted": "#ff0",
  "level_not_tried": "#fefefe",
  "level_current": "#ffa400",
  "level_review_rejected": "#c00",
  "level_review_accepted": "rgb(11, 142, 11)",
  "assessment": "#0094ca",
  "workspace_running_background": "#e5e5e5",
  "dark_purple": "#682A77",
  "bright_green": "#0ebe0e",
  "neon_pink": "#e9118f",
  "link_color": "#0596ce",
  "shadow": "rgba(0, 0, 0, 0.3)",
  "bootstrap_button_blue": "#337ab7",
  "bootstrap_button_red": "#d9534f",
  "bootstrap_error_background": "#f2dede",
  "bootstrap_error_text": "#b94a48",
  "bootstrap_v3_error_text": "#a94442",
  "bootstrap_error_border": "#ebccd1",
  "bootstrap_warning_background": "#fcf8e3",
  "bootstrap_warning_text": "#c09853",
  "bootstrap_warning_border": "#faebcc",
  "bootstrap_border_color": "#cccccc",
  "bootstrap_success_background": "#dff0d8",
  "bootstrap_success_text": "#468847",
  "bootstrap_success_border": "#d6e9c6",
  "droplet_light_green": "#d3e965",
  "droplet_blue": "#64b5f6",
  "droplet_bright_blue": "#19c3e1",
  "droplet_yellow": "#fff176",
  "droplet_orange": "#ffb74d",
  "droplet_red": "#f78183",
  "droplet_cyan": "#4dd0e1",
  "droplet_pink": "#f57ac6",
  "droplet_purple": "#bb77c7",
  "droplet_green": "#68d995",
  "droplet_white": "#fff",
  "oceans_deep_blue": "rgb(2, 0, 28)",
  "dark_black": "#121212",
  "brand_primary_light": "#E0F8F9",
  "brand_primary_default": "#009EB0",
  "brand_primary_dark": "#007785",
  "brand_secondary_light": "#E0D1EC",
  "brand_secondary_default": "#8C52BA",
  "brand_secondary_dark": "#6C468A",
  "brand_accent_default": "#ED6060",
  "brand_teal_5": "#DFF2F4",
  "product_affirmative_default": "#3EA33E",
  "product_negative_light": "#FDEBE9",
  "product_negative_default": "#E5311A",
  "product_negative_dark": "#AA2513",
  "product_caution_default": "#F9CB28",
  "product_information_default": "#007ACC",
  "neutral_white": "#FFFFFF",
  "neutral_light": "#F7F8FA",
  "neutral_gray10": "#EAEBEB",
  "neutral_dark10": "#EAECEF",
  "neutral_dark20": "#D1D4D8",
  "neutral_dark30": "#BEC2C7",
  "neutral_dark40": "#ABB0B6",
  "neutral_dark50": "#989EA5",
  "neutral_dark60": "#858C94",
  "neutral_dark70": "#727A83",
  "neutral_dark80": "#5F6872",
  "neutral_dark90": "#4C5661",
  "neutral_dark": "#292F36",
  "google_brand_color": "#0F9D58",
  "microsoft_brand_color": "#FFB900",
  "facebook_brand_color": "#3B5998",
  "clever_brand_color": "#1464FF",
  "light_primary_100": "#BFE4E8",
  "light_primary_500": "#0093A4",
  "light_primary_700": "#007785",
  "light_secondary_100": "#E2D4EE",
  "light_secondary_500": "#8C52BA",
  "light_secondary_700": "#6C468A",
  "light_gray_50": "#F7F8FA",
  "light_gray_100": "#EAECEF",
  "light_gray_200": "#D1D4D8",
  "light_gray_300": "#BEC2C7",
  "light_gray_400": "#ABB0B6",
  "light_gray_500": "#989EA5",
  "light_gray_600": "#858C94",
  "light_gray_700": "#727A83",
  "light_gray_800": "#5F6872",
  "light_gray_900": "#4C5661",
  "light_gray_950": "#394450",
  "light_affirmative_100": "#E2F1E2",
  "light_affirmative_500": "#3EA33E",
  "light_affirmative_700": "#2D742D",
  "light_negative_100": "#FBE0DD",
  "light_negative_500": "#E5311A",
  "light_negative_700": "#B24949",
  "light_caution_100": "#FEF7DF",
  "light_caution_500": "#F9CB28",
  "light_caution_700": "#C7A220",
  "light_accent_100": "#FAD7D7",
  "light_accent_500": "#ED6060",
  "light_accent_700": "#D84C4C",
  "light_aqua_100": "#CEFFFD",
  "light_aqua_500": "#3CFFF8",
  "light_aqua_700": "#30CCC6",
  "light_orange_100": "#FFEDCC",
  "light_orange_500": "#FFA400",
  "light_orange_700": "#CC8300",
  "light_info_100": "#DCEFFB",
  "light_info_500": "#1892E3",
  "light_info_700": "#1375B6",
  "light_white": "#FFFFFF",
  "light_black": "#292F36",
  "light_black_opacity_90": "rgba(41, 47, 54, 0.90)",
  "light_black_opacity-80": "rgba(41, 47, 54, 0.80)",
  "light_black_opacity-70": "rgba(41, 47, 54, 0.70)",
  "light_black_opacity-60": "rgba(41, 47, 54, 0.60)",
  "light_black_opacity-50": "rgba(41, 47, 54, 0.50)",
  "light_black_opacity-40": "rgba(41, 47, 54, 0.40)",
  "light_black_opacity-30": "rgba(41, 47, 54, 0.30)",
  "light_black_opacity-20": "rgba(41, 47, 54, 0.20)",
  "light_black_opacity-10": "rgba(41, 47, 54, 0.10)",
};
