// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xu820hXsvxEGUcmPPrrT{display:block;text-align:center}.xu820hXsvxEGUcmPPrrT select{margin:0}.SFwQxpwcmMf9pVuRGBHE{display:flex;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/code-studio/components/progress/teacherPanel/teacher-panel.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,iBAAA,CAGF,6BACE,QAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".sortDropdown {\n  display: block;\n  text-align: center;\n}\n\n.sortDropdown select  {\n  margin: 0;\n}\n\n.sortDropdownContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortDropdown": "xu820hXsvxEGUcmPPrrT",
	"sortDropdownContainer": "SFwQxpwcmMf9pVuRGBHE"
};
export default ___CSS_LOADER_EXPORT___;
