// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MbZSzpe5argpVjqoXsLk{display:flex;align-items:center;justify-content:center;position:absolute;width:100%;height:100%;top:0;left:0;background-color:rgba(41,47,54,.5);opacity:0;pointer-events:none;z-index:10;transition:opacity .4s ease}.MJoD_4oHpM_vFyhg2_hG{opacity:1;pointer-events:auto}.Yyw9cHVGMHHAeNa5iLAn{transition-delay:3s}.mIGXBr6NyT_m81xY1Wo9{font-size:35px}", "",{"version":3,"sources":["webpack://./src/music/views/loading-overlay.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,KAAA,CACA,MAAA,CACA,kCAAA,CACA,SAAA,CACA,mBAAA,CACA,UAAA,CACA,2BAAA,CAEA,sBACE,SAAA,CACA,mBAAA,CAGF,sBACE,mBAAA,CAIJ,sBACE,cAAA","sourcesContent":["@import 'color.scss';\n\n.loadingContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  background-color: rgba($neutral_dark, 0.5);\n  opacity: 0;\n  pointer-events: none;\n  z-index: 10;\n  transition: opacity 0.4s ease;\n\n  &Show {\n    opacity: 1;\n    pointer-events: auto;\n  }\n\n  &Delay {\n    transition-delay: 3s;\n  }\n}\n\n.loadingSpinner {\n  font-size: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingContainer": "MbZSzpe5argpVjqoXsLk",
	"loadingContainerShow": "MJoD_4oHpM_vFyhg2_hG",
	"loadingContainerDelay": "Yyw9cHVGMHHAeNa5iLAn",
	"loadingSpinner": "mIGXBr6NyT_m81xY1Wo9"
};
export default ___CSS_LOADER_EXPORT___;
