module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    "use strict";
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = "<% var msg = require('@cdo/locale') %>\n<%\n/**\n * This block destructures the local variables for use within this ejs template.\n */\nvar data = locals.data;\n%>\n\n<div id=\"make-your-own\">\n\n  <h1><a href=<%= data.makeUrl %>><%= data.makeString %></a></h1>\n  <a href=<%= data.makeUrl %>><img src=<%= data.makeImage %>></a>\n\n</div>\n", __filename = "src/templates/makeYourOwn.html.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        var msg = require("@cdo/locale");
        __append("\n");
        __line = 2;
        var data = locals.data;
        __line = 7;
        __append('\n\n<div id="make-your-own">\n\n  <h1><a href=');
        __line = 11;
        __append(escapeFn(data.makeUrl));
        __append(">");
        __append(escapeFn(data.makeString));
        __append("</a></h1>\n  <a href=");
        __line = 12;
        __append(escapeFn(data.makeUrl));
        __append("><img src=");
        __append(escapeFn(data.makeImage));
        __append("></a>\n\n</div>\n");
        __line = 15;
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}