// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iG_U7133clrJGp9EvJu0 a:hover{text-decoration:underline !important}", "",{"version":3,"sources":["webpack://./src/templates/content-container.module.scss"],"names":[],"mappings":"AACE,8BAEE,oCAAA","sourcesContent":[".contentContainerHeading {\n  a:hover {\n    //We need !important to overwrite the default state inline style (inline hover state is not working, unfortunately)\n    text-decoration: underline !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainerHeading": "iG_U7133clrJGp9EvJu0"
};
export default ___CSS_LOADER_EXPORT___;
