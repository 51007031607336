/* eslint-disable */

// This is a generated file and SHOULD NOT BE EDITED MANUALLY!!
// Contents are generated as part of grunt build
// Source of truth is lib/cdo/shared_constants.rb and files in lib/cdo/shared_constants/

export const DefaultLocale = 'en-US' as const;

export const ArtistAutorunOptions = {
  "full_auto_run": "FULL_AUTO_RUN"
} as const;

export const LevelKind = {
  "peer_review": "peer_review",
  "assessment": "assessment",
  "puzzle": "puzzle",
  "unplugged": "unplugged",
  "level": "level",
  "stage_extras": "stage_extras"
} as const;

export const LevelStatus = {
  "not_tried": "not_tried",
  "submitted": "submitted",
  "perfect": "perfect",
  "passed": "passed",
  "attempted": "attempted",
  "review_accepted": "review_accepted",
  "review_rejected": "review_rejected",
  "dots_disabled": "dots_disabled",
  "free_play_complete": "free_play_complete",
  "completed_assessment": "completed_assessment"
} as const;

export const UserLevelInteractions = {
  "click_help_and_tips": "click_help_and_tips"
} as const;

export const SectionLoginType = {
  "word": "word",
  "picture": "picture",
  "email": "email",
  "google_classroom": "google_classroom",
  "clever": "clever",
  "lti_v1": "lti_v1"
} as const;

export const StudentGradeLevels = [
  "K",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "Other"
] as const;

export const PlGradeValue = 'pl' as const;

export const PostMilestoneMode = {
  "all": "all",
  "successful_runs_and_final_level_only": "successful_runs_and_final_level_only",
  "final_level_only": "final_level_only"
} as const;

export const AlwaysPublishableProjectTypes = [
  "artist",
  "frozen",
  "playlab",
  "gumball",
  "iceage",
  "infinity",
  "minecraft_adventurer",
  "minecraft_designer",
  "minecraft_hero",
  "minecraft_aquatic",
  "starwars",
  "starwarsblocks",
  "starwarsblocks_hour",
  "flappy",
  "bounce",
  "sports",
  "basketball",
  "artist_k1",
  "playlab_k1",
  "dance",
  "poetry",
  "poetry_hoc",
  "thebadguys",
  "music",
  "pythonlab"
] as const;

export const AllPublishableProjectTypes = [
  "artist",
  "frozen",
  "playlab",
  "gumball",
  "iceage",
  "infinity",
  "minecraft_adventurer",
  "minecraft_designer",
  "minecraft_hero",
  "minecraft_aquatic",
  "starwars",
  "starwarsblocks",
  "starwarsblocks_hour",
  "flappy",
  "bounce",
  "sports",
  "basketball",
  "artist_k1",
  "playlab_k1",
  "dance",
  "poetry",
  "poetry_hoc",
  "thebadguys",
  "music",
  "pythonlab",
  "applab",
  "gamelab",
  "spritelab"
] as const;

export const ConditionallyPublishableProjectTypes = [
  "applab",
  "gamelab"
] as const;

export const AbuseConstants = {
  "ABUSE_THRESHOLD": 15
} as const;

export const ErrorSeverityLevels = {
  "WARN": 2,
  "ERROR": 3,
  "FATAL": 4
} as const;

export const RestrictedPublishProjectTypes = [
  "spritelab"
] as const;

export const RubricUnderstandingLevels = {
  "EXTENSIVE": 3,
  "CONVINCING": 2,
  "LIMITED": 1,
  "NONE": 0
} as const;

export const RubricAiEvaluationStatus = {
  "QUEUED": 0,
  "RUNNING": 1,
  "SUCCESS": 2,
  "FAILURE": 1000,
  "PII_VIOLATION": 1001,
  "PROFANITY_VIOLATION": 1002,
  "REQUEST_TOO_LARGE": 1003,
  "STUDENT_LIMIT_EXCEEDED": 1004,
  "TEACHER_LIMIT_EXCEEDED": 1005
} as const;

export const RubricAiEvaluationLimits = {
  "STUDENT_LIMIT": 10,
  "TEACHER_LIMIT": 10
} as const;

export const EmailLinks = {
  "PRIVACY_POLICY_URL": "https://code.org/privacy",
  "CONTACT_US_URL": "https://code.org/contact",
  "TOS_URL": "https://code.org/tos",
  "STUDENT_PRIVACY_PLEDGE_URL": "https://studentprivacypledge.org/signatories/",
  "COMMON_SENSE_MEDIA_URL": "https://privacy.commonsense.org/evaluation/code.org",
  "CDO_SUPPORT_MAILTO": "mailto:support@code.org"
} as const;

export const ChildAccountComplianceStates = {
  "GRACE_PERIOD": "p",
  "LOCKED_OUT": "l",
  "PERMISSION_GRANTED": "g"
} as const;

export const CensusConstants = {
  "CURRENT_CENSUS_SCHOOL_YEAR": 2024
} as const;

export const DanceSongManifestFilename = 'songManifest2024_v4.json' as const;

export const AiInteractionStatus = {
  "ERROR": "error",
  "PII_VIOLATION": "pii_violation",
  "PROFANITY_VIOLATION": "profanity_violation",
  "USER_INPUT_TOO_LARGE": "user_input_too_large",
  "OK": "ok",
  "UNKNOWN": "unknown"
} as const;

export const AiTutorInteractionStatus = {
  "ERROR": "error",
  "PII_VIOLATION": "pii_violation",
  "PROFANITY_VIOLATION": "profanity_violation",
  "USER_INPUT_TOO_LARGE": "user_input_too_large",
  "OK": "ok",
  "UNKNOWN": "unknown"
} as const;

export const AiTutorTypes = {
  "COMPILATION": "compilation",
  "VALIDATION": "validation",
  "GENERAL_CHAT": "general_chat",
  "COMPLETION": "completion"
} as const;

export const AiRequestExecutionStatus = {
  "NOT_STARTED": 0,
  "QUEUED": 1,
  "RUNNING": 2,
  "SUCCESS": 3,
  "FAILURE": 1000,
  "USER_PROFANITY": 1001,
  "USER_PII": 1002,
  "MODEL_PROFANITY": 1003,
  "MODEL_PII": 1004,
  "USER_INPUT_TOO_LARGE": 1005
} as const;

export const AiChatModelIds = {
  "ARITHMO": "gen-ai-arithmo2-mistral-7b",
  "BIOMISTRAL": "gen-ai-biomistral-7b",
  "MISTRAL": "gen-ai-mistral-7b-inst-v01",
  "KAREN": "gen-ai-karen-creative-mistral-7b",
  "PIRATE": "gen-ai-mistral-pirate-7b"
} as const;

export const FeaturedProjectStatus = {
  "active": "active",
  "bookmarked": "bookmarked",
  "archived": "archived"
} as const;

export const FeaturedProjectConstants = {
  "MAX_REQUESTS_PER_CATEGORY": 15
} as const;

export const CapLinks = {
  "PARENTAL_CONSENT_GUIDE_URL": "https://support.code.org/hc/en-us/articles/15465423491085-How-do-I-obtain-parent-or-guardian-permission-for-student-accounts"
} as const;

export const LmsLinks = {
  "INTEGRATION_GUIDE_URL": "https://support.code.org/hc/en-us/articles/23120014459405-Learning-Management-System-LMS-and-Single-Sign-On-SSO-Integrations-and-Support-for-Code-org",
  "INSTALL_INSTRUCTIONS_URL": "https://support.code.org/hc/en-us/articles/23621907533965-Install-Code-org-Integrations-for-your-Learning-Management-System",
  "ROSTER_SYNC_INSTRUCTIONS_URL": "https://support.code.org/hc/en-us/articles/23621978654605-Sync-Rosters-with-your-Learning-Management-System",
  "ADDITIONAL_FEEDBACK_URL": "https://studio.code.org/form/lms_integration_modal_feedback",
  "SUPPORTED_METHODS_URL": "https://github.com/code-dot-org/code-dot-org/blob/staging/docs/lti-integration.md#option-2-manual-entry"
} as const;

export const UserTypes = {
  "STUDENT": "student",
  "TEACHER": "teacher"
} as const;

export const NonSchoolOptions = {
  "SELECT_A_SCHOOL": "selectASchool",
  "CLICK_TO_ADD": "clickToAdd",
  "NO_SCHOOL_SETTING": "noSchoolSetting"
} as const;

export const UsStates = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "FL": "Florida",
  "GA": "Georgia",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PA": "Pennsylvania",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VA": "Virginia",
  "WA": "Washington",
  "DC": "Washington, D.C.",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
} as const;

export const ProjectSubmissionStatus = {
  "CAN_SUBMIT": "can_submit",
  "ALREADY_SUBMITTED": "already_submitted",
  "PROJECT_TYPE_NOT_ALLOWED": "project_type_not_allowed",
  "RESTRICTED_SHARE_MODE": "restricted_share_mode",
  "SHARING_DISABLED": "sharing_disabled",
  "OWNER_TOO_NEW": "owner_too_new",
  "PROJECT_TOO_NEW": "project_too_new"
} as const;
