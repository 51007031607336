module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    "use strict";
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = "<% var msg = require('@cdo/locale') %>\n<%\n/**\n * This block destructures the local variables for use within this ejs template.\n */\nvar assetUrl = locals.assetUrl;\n%>\n\n<% var root = location.protocol + '//' + location.host.replace('learn\\.', '').replace('studio\\.', ''); %>\n\n<div id=\"learn\">\n\n  <h1><a href=\"<%= root %>\"><%= msg.wantToLearn() %></a></h1>\n  <a href=\"<%= root %>\"><img id=\"learn-to-code\" src=\"<%= assetUrl('media/promo.png') %>\"></a>\n  <a href=\"<%= root %>\"><%= msg.watchVideo() %></a>\n  <a href=\"<%= root %>\"><%= msg.tryHOC() %></a>\n  <a href=\"<%= location.protocol + '//' + location.host %>\"><%= msg.signup() %></a>\n\n</div>\n", __filename = "src/templates/learn.html.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        var msg = require("@cdo/locale");
        __append("\n");
        __line = 2;
        var assetUrl = locals.assetUrl;
        __line = 7;
        __append("\n\n");
        __line = 9;
        var root = location.protocol + "//" + location.host.replace("learn.", "").replace("studio.", "");
        __append('\n\n<div id="learn">\n\n  <h1><a href="');
        __line = 13;
        __append(escapeFn(root));
        __append('">');
        __append(escapeFn(msg.wantToLearn()));
        __append('</a></h1>\n  <a href="');
        __line = 14;
        __append(escapeFn(root));
        __append('"><img id="learn-to-code" src="');
        __append(escapeFn(assetUrl("media/promo.png")));
        __append('"></a>\n  <a href="');
        __line = 15;
        __append(escapeFn(root));
        __append('">');
        __append(escapeFn(msg.watchVideo()));
        __append('</a>\n  <a href="');
        __line = 16;
        __append(escapeFn(root));
        __append('">');
        __append(escapeFn(msg.tryHOC()));
        __append('</a>\n  <a href="');
        __line = 17;
        __append(escapeFn(location.protocol + "//" + location.host));
        __append('">');
        __append(escapeFn(msg.signup()));
        __append("</a>\n\n</div>\n");
        __line = 20;
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}