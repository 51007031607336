// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rurfrjWhr7xg0YlFZP9Y{min-height:40px !important;padding:10px 8px}.dz0VgrgnMrSrV8uowiLr{margin:0;padding:0;border:none;border-radius:0;background-color:rgba(0,0,0,0);font-size:13px}.dz0VgrgnMrSrV8uowiLr:hover{box-shadow:none}.dz0VgrgnMrSrV8uowiLr:active{border:none !important}", "",{"version":3,"sources":["webpack://./src/templates/instructions/codeReviewV2/code-review-comment-editor.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAEA,sBACE,0BAAA,CACA,gBAAA,CAGF,sBCYE,QAAA,CACA,SAAA,CACA,WAAA,CACA,eAAA,CACA,8BAAA,CDdA,cAAA,CCgBA,4BACE,eAAA,CAGF,6BACE,sBAAA","sourcesContent":["@import '../../../mixins.scss';\n\n.editableTextArea {\n  min-height: 40px !important;\n  padding: 10px 8px;\n}\n\n.codeButton {\n  @include remove-button-styles;\n  font-size: 13px;\n}\n","// Removes (most) default button styling. Useful for retaining a clickable component's\n// original presentation when converting it to a <button>\n@mixin remove-button-styles(\n  $color: false,\n  $font-size: false,\n  $line-height: false\n) {\n  @if $color {\n    color: $color;\n  }\n\n  @if $font-size {\n    font-size: $font-size;\n  }\n\n  @if $line-height {\n    line-height: $line-height;\n  }\n\n  margin: 0;\n  padding: 0;\n  border: none;\n  border-radius: 0;\n  background-color: transparent;\n\n  &:hover {\n    box-shadow: none;\n  }\n\n  &:active {\n    border: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editableTextArea": "rurfrjWhr7xg0YlFZP9Y",
	"codeButton": "dz0VgrgnMrSrV8uowiLr"
};
export default ___CSS_LOADER_EXPORT___;
