// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GTAl08T7aqVJs585lj0Y{border:2px solid #000}.thgJAezVXF9nEDOsbzYD{margin-inline-end:8px}", "",{"version":3,"sources":["webpack://./src/templates/instructions/hint-prompt.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,sBACE,qBAAA","sourcesContent":[".button {\n  border: 2px solid black;\n  &Yes {\n    margin-inline-end: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "GTAl08T7aqVJs585lj0Y",
	"buttonYes": "thgJAezVXF9nEDOsbzYD"
};
export default ___CSS_LOADER_EXPORT___;
