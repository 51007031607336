// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eoZMmrFEGbNgRL_QWBE5{text-align:left;margin-left:10px}", "",{"version":3,"sources":["webpack://./src/templates/projects/project-name-failure-dialog.module.scss"],"names":[],"mappings":"AAGA,sBACI,eAAA,CACA,gBAAA","sourcesContent":["@import \"color.scss\";\n@import \"font.scss\";\n\n.contents {\n    text-align: left;\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contents": "eoZMmrFEGbNgRL_QWBE5"
};
export default ___CSS_LOADER_EXPORT___;
