// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".H8km6PMEvKQFW2ta_mqt{float:left;display:flex;margin:0}.zi8_ttDtOgTR6H1vdZcQ{margin-top:0;margin-bottom:0;margin-left:10;margin-right:0;box-shadow:\"none\"}", "",{"version":3,"sources":["webpack://./src/code-studio/components/header/project-header.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,eAAA,CACA,cAAA,CACA,cAAA,CACA,iBAAA","sourcesContent":[".buttonWrapper {\n  float: left;\n  display: flex;\n  margin: 0;\n}\n\n.buttonSpacing {\n  margin-top: 0;\n  margin-bottom: 0;\n  margin-left: 10;\n  margin-right: 0;\n  box-shadow: 'none';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": "H8km6PMEvKQFW2ta_mqt",
	"buttonSpacing": "zi8_ttDtOgTR6H1vdZcQ"
};
export default ___CSS_LOADER_EXPORT___;
