module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    "use strict";
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<% var i18n = require(\'@cdo/locale\'); %>\n<%\n/**\n * This block destructures the local variables for use within this ejs template.\n */\nvar funcName = locals.funcName;\nvar functionName = locals.functionName;\nvar isProperty = locals.isProperty;\nvar tipPrefix = locals.tipPrefix;\nvar functionShortDescription = locals.functionShortDescription;\nvar parameters = locals.parameters;\nvar signatureOverride = locals.signatureOverride;\nvar showExamplesLink = locals.showExamplesLink;\nvar currentParameterIndex = locals.currentParameterIndex;\n%>\n<div class="function-name">\n  <% if (signatureOverride) { -%>\n    <%= signatureOverride %>\n  <% } else {\n    /**\n     * TODO(bjordan): would be nice to split the following line up, can\'t figure\n     * out how to do so without inserting extraneous spaces between parameters.\n     */\n    -%>\n    <%= functionName %>(<% for (var i = 0; i < parameters.length; i++) { -%><span class="tooltip-parameter-name <% if (i === currentParameterIndex) { %> current-tooltip-parameter-name<% } %>"><%- parameters[i].name -%></span><% if (i < parameters.length - 1) { -%>, <% } -%><% } -%>)\n  <% } %>\n</div>\n<% if (parameters[currentParameterIndex] && parameters[currentParameterIndex].description) { %><div><%= parameters[currentParameterIndex].description %></div><% } %>\n<% if (parameters[currentParameterIndex] && parameters[currentParameterIndex].assetTooltip) { %>\n  <div class="tooltip-choose-link">\n    <a href="#"><%= i18n.choosePrefix() %></a>\n  </div>\n<% } %>\n<% if (showExamplesLink) { %>\n  <div class="tooltip-example-link">\n    <a href="#"><%= i18n.examples() %></a>\n  </div>\n<% } %>\n', __filename = "src/blockTooltips/DropletParameterTooltip.html.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        var i18n = require("@cdo/locale");
        __append("\n");
        __line = 2;
        var funcName = locals.funcName;
        var functionName = locals.functionName;
        var isProperty = locals.isProperty;
        var tipPrefix = locals.tipPrefix;
        var functionShortDescription = locals.functionShortDescription;
        var parameters = locals.parameters;
        var signatureOverride = locals.signatureOverride;
        var showExamplesLink = locals.showExamplesLink;
        var currentParameterIndex = locals.currentParameterIndex;
        __line = 15;
        __append('\n<div class="function-name">\n  ');
        __line = 17;
        if (signatureOverride) {
            __append("    ");
            __line = 18;
            __append(escapeFn(signatureOverride));
            __append("\n  ");
            __line = 19;
        } else {
            __line = 24;
            __append("    ");
            __line = 25;
            __append(escapeFn(functionName));
            __append("(");
            for (var i = 0; i < parameters.length; i++) {
                __append('<span class="tooltip-parameter-name ');
                if (i === currentParameterIndex) {
                    __append(" current-tooltip-parameter-name");
                }
                __append('">');
                __append(parameters[i].name);
                __append("</span>");
                if (i < parameters.length - 1) {
                    __append(", ");
                }
            }
            __append(")\n  ");
            __line = 26;
        }
        __append("\n</div>\n");
        __line = 28;
        if (parameters[currentParameterIndex] && parameters[currentParameterIndex].description) {
            __append("<div>");
            __append(escapeFn(parameters[currentParameterIndex].description));
            __append("</div>");
        }
        __append("\n");
        __line = 29;
        if (parameters[currentParameterIndex] && parameters[currentParameterIndex].assetTooltip) {
            __append('\n  <div class="tooltip-choose-link">\n    <a href="#">');
            __line = 31;
            __append(escapeFn(i18n.choosePrefix()));
            __append("</a>\n  </div>\n");
            __line = 33;
        }
        __append("\n");
        __line = 34;
        if (showExamplesLink) {
            __append('\n  <div class="tooltip-example-link">\n    <a href="#">');
            __line = 36;
            __append(escapeFn(i18n.examples()));
            __append("</a>\n  </div>\n");
            __line = 38;
        }
        __append("\n");
        __line = 39;
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}