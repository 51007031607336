/**
 * Dance Party song titles mapped to the artist's Twitter handle to use in the
 * <ShareDialog/> share links. Not all songs are available in all environments.
 * The full list of in-use Dance Party songs can be found at:
 * https://s3.console.aws.amazon.com/s3/buckets/cdo-sound-library/hoc_song_meta/?region=us-east-1&tab=overview
 */

export const SongTitlesToArtistTwitterHandle = {
  backtoyou_selenagomez: 'SelenaGomez',
  bornthisway_ladygaga: 'ladygaga',
  callmemaybe_carlyraejepsen: 'CarlyRaeJepsen',
  cantholdus_macklemore: 'Macklemore',
  cheapthrills_sia: 'Sia',
  heyya_outkast: 'Outkast',
  iliketomoveit_william: 'iamwill',
  getintothegroove_madonna: 'Madonna',
  migente_jbalvin: 'JBalvin',
  needyounow_ladya: 'LadyA',
  notearslefttocry_arianagrande: 'ArianaGrande',
  summer_calvinharris: 'CalvinHarris',
  takeonme_aha: 'Aha_Com',
  isawthesign_aceofbase: 'AceofBase',
  thunder_imaginedragons: 'ImagineDragons',
  canttouchthis_mchammer: 'MCHammer',
  uptownfunk_brunomars: 'BrunoMars',
  vivalavida_coldplay: 'Coldplay',
  wakemeup_aviciialoeblacc: 'AloeBlacc',
  wenospeakamericano_yolandabecool: 'YolandaBeCool',
  // 2019 Songs
  calma_pedrocapo: 'PedroCapo',
  highhopes_panicatthedisco: 'PanicAtTheDisco',
  ificanthaveyou_shawnmendes: 'ShawnMendes',
  introtoshamstep_47SOUL: '47soulofficial',
  starships_nickiminaj: 'NICKIMINAJ',
  sucker_jonasbrothers: 'JonasBrothers',
  // 2020 Songs
  dancemonkey_tonesandi: 'tonesandimusic',
  dernieredanse_indila: 'Indila',
  dontstartnow_dualipa: 'DUALIPA',
  euphoria_loreen: 'LOREEN_TALHAOUI',
  ilkadimisenat_kenandogulu: 'kenandogulu',
  jerusalema_masterkg: 'MasterKGsa',
  odetocode_brendandominicpaolini: 'CodeWeekEU',
  rainonme_ladygagaftarianagrande: 'ladygaga',
  savagelove_jasonderulo: 'jasonderulo',
  sayso_dojacat: 'DojaCat',
  // 2021 Songs
  aire_jessejoy: 'jesseyjoy',
  despedidaycierre_vanesamartin: 'vanesamartin_',
  // These tracks available locally, tweet @codeorg to avoid spamming anyone.
  jazzy_beats: 'codeorg',
  synthesize: 'codeorg',
  // These tracks are currently only potential. If we get licensing, we'll be
  // able to tweet the artist without an additional DTP.
  onedance_drake: 'Drake',
  elprestamo_maluma: 'Maluma',
  despacito_luisfonsi: 'LuisFonsi',
  tunaktunaktun_dalermehndi: 'DalerMehndi',
  sapescommejamias_maitregims: 'MaitreGIMS',
  lesercitodelselfie_takagi: 'TakagiBeatz',
  gangnamstyle_psy: 'Psy_Oppa',
  sophia_alvarosoler: 'AsolerMusic',
  danzakuduro_donomar: 'DONOMAR',
  cestlavie_khaled: 'Khaled_Officiel',
  isthatforme_anitta: 'Anitta',
  janti_muratboz: 'MuratBoz',
  gee_girlsgen: 'GirlsGeneration',
  growingpains_alessiacara: 'AlessiaCara',
  stay_thekidlaroi: 'TheKidLaroi',
  // 2022 Songs
  '2beloved_lizzo': 'Lizzo',
  asitwas_harrystyles: 'Harry_Styles',
  astronautintheocean_maskedwolf: 'MaskedWolfMusic',
  breakmysoul_beyonce: 'Beyonce',
  good4u_oliviarodrigo: 'OliviaRodrigo',
  heatwaves_glassanimals: 'GlassAnimals',
  higherpower_coldplay: 'Coldplay',
  levitating_dualipa: 'DUALIPA',
  sunflower_postmaloneftswaelee: 'PostMalone',
  sunroof_nickyoureanddazy: 'Nicky_Youre',
  taconesrojos_sebastianyatra: 'SebastianYatra',
  watermelonsugar_harrystyles: 'Harry_Styles',
  wedonttalkaboutbruno_encanto: 'EncantoMovie',
  // 2023 Songs
  butter_bts: 'BTS',
  dynamite_bts: 'BTS',
  bizcochito_rosalia: 'Rosalia',
  cupid_fiftyfifty: 'we_fiftyfifty',
  flowers_mileycyrus: 'MileyCyrus',
  illegalweapon20_jasminesandlasgarrysandhu: 'JasmineSandlas',
  likeagirl_lizzo: 'Lizzo',
  wafflehouse_jonasbrothers: 'JonasBrothers',
};
